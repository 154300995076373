import { PeachElement } from "../Element";
import { html } from "lit";
import { AccordionItem } from "./item";

/**
 * @element gp-accordion
 * @slot default - The accordion items
 * @property {String} type - "single" or "multiple", defaults to "single"
 * @property {Boolean} type - true or false, defaults to true
 */
export class Accordion extends PeachElement {
  static get properties() {
    return {
      type: { type: String }, // "single" or "multiple"
      openFirst: { type: Boolean }, // true or false
    };
  }

  constructor() {
    super();
    this.type = "single";
    this.openFirst = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("accordion:click", this._onToggle.bind(this));
    this.onInit();
  }

  onInit() {
    if (this.openFirst && this.children) {
      [...this.children][0]._toggle();
    }
  }

  _onToggle(e) {
    if (this.type === "single") {
      [...this.children].forEach((child) => {
        if (child._id !== e.detail.id) {
          child.open = false;
        }
      });
    }
  }

  render() {
    return html`
      <div>
        <slot></slot>
      </div>
    `;
  }
}

customElements.define("peach-accordion", Accordion);
