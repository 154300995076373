import { inView, animate } from "motion";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import Navigation from "./Blocks/Header/navigation";
import WebComponents from "./js/WebComponents/index.js";
import '@glidejs/glide/dist/css/glide.core.min.css';
import "./main.css";

Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();

const domReady = () => {
  Navigation();
  initModules();
  initAnimations();
};

/*

Each module should be treated as an individual block. 
Please pass each node as module param. 
Module path is case-sensitive. Wrong path will throw an error on dev server after running `npm run build`.

*/
function initModules() {
  new WebComponents();

  // News Latest
  const articleListNodes = document.querySelectorAll(".giantpeach-articlelist");

  if (articleListNodes.length) {
    import("./Blocks/ArticleList/ArticleList.js").then(({default: ArticleList}) => {
      articleListNodes.forEach(node => {
        ArticleList(node);
      });
    });
  };

  // Banner Project 
  const bannerProjectNodes = document.querySelectorAll(".giantpeach-bannerproject");

  if (bannerProjectNodes.length) {
    import("./Blocks/BannerProject/BannerProject.js").then(({default: BannerProject}) => {
      bannerProjectNodes.forEach(node => {
        BannerProject(node);
      });
    });
  };

  // Projects Carousel
  const projectsCarouselNodes = document.querySelectorAll(".giantpeach-projectscarousel");

  if (projectsCarouselNodes.length) {
    import("./Blocks/ProjectsCarousel/ProjectsCarousel.js").then(({default: ProjectsCarousel}) => {
      projectsCarouselNodes.forEach(node => {
        ProjectsCarousel(node);
      });
    });
  };
}

function initAnimations() {
  let transitionableBlocks = document.querySelectorAll(".transition-block");
  let inViewStop = inView(transitionableBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1, transform: "none" },
      { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: "translateY(60px)" },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  document.addEventListener("block:load", () => {
    transitionableBlocks = document.querySelectorAll(".transition-block");
    inViewStop();
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: "none" },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { opacity: 0, transform: "translateY(60px)" },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });

  let transitionableFadeBlocks = document.querySelectorAll(".transition-block-fade");
  let inViewStopFade = inView(transitionableFadeBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1 },
      { delay: target.dataset.delay ?? 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0 },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });
  
  let transitionableBlocksProse = document.querySelectorAll(".transition-block-prose");
  inView(transitionableBlocksProse, ({ target }) => {
    target.classList.add('animated');

    return () => {
      target.classList.remove('animated');
    };
  });

}

document.addEventListener("DOMContentLoaded", domReady);
