import { Trigger } from "./Trigger";
import { OffCanvas } from "./OffCanvas";
import { OffCanvasInner } from "./OffCanvas/inner";
import { Accordion } from "./Accordion";

//console.log(document.styleSheets);

//let supportConstructableStyleSheets = true;

//let sheet = null;
//let style = null;

//try {
//  sheet = new CSSStyleSheet();
//} catch(e) {
//  style = document.createElement('style');
//  supportConstructableStyleSheets = false;
//}

export default function () {}
