import { PeachElement } from "../Element";
import { html } from "lit";

function slugify(str) {
  return str.toLowerCase().replace(/\s/g, "-");
}

/**
 * @element gp-accordion-item
 * @slot header - The header of the accordion item
 * @slot content - The content of the accordion item
 *
 * This element will automatically use the first child element as the header
 * and any other children as the content.
 */
export class AccordionItem extends PeachElement {
  static get properties() {
    return {
      open: { type: Boolean, reflect: true },
      _id: { type: String },
      id: { type: String },
      elNumber: { type: Number },
      headerClass: { type: String },
    };
  }

  constructor() {
    super();
    this.open = false;
    this.elNumber = parseInt(Array.prototype.indexOf.call(this.parentNode.children, this)) + 1;
  }

  connectedCallback() {
    super.connectedCallback();
    this._distributeChildren();
  }

  _distributeChildren() {
    // get first child
    if (this.children.length > 0) {
      this.children[0].setAttribute("slot", "header");
      this._id = slugify(this.children[0].textContent);
      this.id = this._id;
    }

    // get all other children
    if (this.children.length > 1) {
      for (let i = 1; i < this.children.length; i++) {
        this.children[i].setAttribute("slot", "content");
      }
    }
  }

  update(changedProperties) {
    super.update(changedProperties);
    // console.log(changedProperties);

    //if (changedProperties.has("open")) {
    //  if (changedProperties.open == false) {

    //  }
    //}
  }

  get innerClasses() {
    const clses = {
      grid: true,
      "grid-rows-[0fr]": !this.open,
      "grid-rows-[1fr]": this.open,
      "transition-all": true,
      "duration-500": true,
    };

    return Object.keys(clses)
      .filter((cls) => clses[cls])
      .join(" ");
  }

  _toggle() {
    this.open = !this.open;

    this.dispatchEvent(
      new CustomEvent("accordion:click", {
        detail: {
          open: this.open,
          id: this._id,
        },
        composed: true,
        bubbles: true,
      }),
    );
  }

  render() {
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    return html`
      <div>
        <dt>
          <button
            class="${this.headerClass}"
            @click="${this._toggle}"
            aria-controls="${this._id}"
            ?aria-expanded="${this.open}"
          >
            <span>${zeroPad(this.elNumber, 2)}</span>
            <slot name="header"></slot>
            <span class="inline-block ml-auto text-[22px] lg:text-[26px]">
              <svg class="${this.open ? 'hidden' : 'inline-block'} w-[1em]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M248 72l0-24-48 0 0 24 0 160L40 232l-24 0 0 48 24 0 160 0 0 160 0 24 48 0 0-24 0-160 160 0 24 0 0-48-24 0-160 0 0-160z"/></svg>
              <svg class="${this.open ? 'inline-block' : 'hidden'} w-[1em]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 272l-16 0L32 272l-16 0 0-32 16 0 384 0 16 0 0 32z"/></svg>
            </span>
          </button>
        </dt>
        <dd id="${this._id}" class="${this.innerClasses}">
          <div class="overflow-hidden">
            <slot name="content"></slot>
          </div>
        </dd>
      </div>
    `;
  }
}

customElements.define("peach-accordion-item", AccordionItem);
