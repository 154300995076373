import { animate, stagger, timeline } from 'motion';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import throttle from 'lodash.throttle';

const Navigation = () => {
  const stickyNavBar = document.getElementById('page-sticky-navbar');
  const navBar = document.getElementById('page-navbar');
  const navEl = document.getElementById('page-navbar-sidebar');
  const navOverflowEl = document.querySelector('.side-nav-overflow-wrapper');
  const navItemEls = navEl?.querySelectorAll('.nav-item-js');

  /* Button nav trigger */
  document.addEventListener('nav:toggle', (e) => {
    if (e.detail) {
      openNav();
    } else {
      closeNav();
    }
  });

  /* Navbar hide on scroll */
  let lastScrollTop = 0;
  const navToggleOffset = document.querySelector('.giantpeach-banner')?.offsetHeight ?? window.innerHeight;

  window.addEventListener('scroll', throttle( () => {
    const scrollTop = window.scrollY;

    /* After 60px translate/top / hide on scroll down */
    if (scrollTop >= navToggleOffset) {
      stickyNavBar.classList.add('!translate-y-0');
    } else {
      stickyNavBar.classList.remove('!translate-y-0');
    }
  }, 100));

  const openNav = () => {
    disableBodyScroll(navOverflowEl);

    timeline([
      [navEl, { x: 0 }, { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] }],
      [
        navItemEls,
        { opacity: 1 },
        { duration: 0.3, delay: stagger(0.1), easing: [0.25, 0.1, 0.25, 1], at: '-0.2' },
      ],
    ]);
  };

  const closeNav = (duration = 0.3) => {
    enableBodyScroll(navOverflowEl);

    timeline([
      [
        navEl,
        { x: '-100%' },
        { duration: duration, easing: [0.25, 0.1, 0.25, 1] },
      ],
      [
        navItemEls,
        { opacity: 0 },
        {
          duration: duration,
          easing: [0.25, 0.1, 0.25, 1],
        },
      ],
    ]);
  };

  closeNav(0);
};

export default Navigation;
